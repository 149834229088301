export default {
   "sidebar.dashboard" : "Dashboard",
   "sidebar.dashboard1" : "Dashboard 1",
   "sidebar.dashboard2" : "Dashboard 2",
   "sidebar.dashboard3" : "Dashboard 3",
	"sidebar.fullpagemenu" : "Full Page Menu",
   "sidebar.videoPlayer" : "Video Player",
   "sidebar.level1": "Level 1",
	"sidebar.level2": "Level 2",
	"sidebar.level3": "Level 3",
   "sidebar.webanalytics": "Web Analytics",
   "sidebar.saas": "Saas",
   "sidebar.ecommerce": "Ecommerce",
   "sidebar.shop": "Shop",
   "sidebar.cart": "Cart",
   "sidebar.checkout": "Checkout",
   "sidebar.productdetails": "Product Details",
   "sidebar.invoice": "Invoice",
   "sidebar.chat": "Chat",
   "sidebar.email":"Email",
   "sidebar.calendar":"Calendar",
   "sidebar.payment": "Payment",
   "sidebar.signaturePad": "Signature Pad",
   "sidebar.userManagement": "User Management",
   "sidebar.profile": "Profile",
   "sidebar.modern": "Modern",
   "sidebar.standard": "Standard",
   "sidebar.style1": "Style 1",
   "sidebar.style2": "Style 2",
   "sidebar.contactGrid":"Contact Grid",
   "sidebar.extended":"Extended",
   "sidebar.userSettings": "User Settings",
   "sidebar.pages": "Pages",
	"sidebar.faq": "FAQ",
	"sidebar.pricing": "Pricing",
   "sidebar.pricingv1" : "Pricing V1",
   "sidebar.pricingv2": "Pricing V2",
   "sidebar.pricingUpgrade": "Pricing Upgrade",
   "sidebar.verticalTimeline": "Vertical Timeline",
   "sidebar.horizontalTimeline":" Horizontal Timeline",
   "sidebar.horizontalStepper":"Horizontal Stepper",
   "sidebar.verticalStepper": "Vertical Stepper",
   "sidebar.blog":"Blog",
   "sidebar.blogGrid": "Blog Grid",
   "sidebar.blogDetails": "Blog Details",
   "sidebar.uiComponents":"UI Components",
   "sidebar.appBar":"AppBar",
   "sidebar.avatars": "Avatars",
   "sidebar.buttons": "Buttons",
   "sidebar.bottomNavigations": "Bottom Navigations",
   "sidebar.chip": "Chip",
   "sidebar.lists": "Lists",
   "sidebar.modals":"Modals",
   "sidebar.tables": "Tables",
   "sidebar.basicTable": "Basic Table",
   "sidebar.agGrid": "AgGrid",
   "sidebar.searchTable" : "Search Table",
   "sidebar.customTable": "Custom Table",
	"sidebar.sessions" : "Sessions",
	"sidebar.layout1":"Layout 1",
	"sidebar.layout2":"Layout 2",
   "sidebar.signIn": "SignIn",
   "sidebar.signUp":"SignUp",
   "sidebar.forgotPassword": "Forgot Password",
   "sidebar.errorPages": "Error Pages",
   "sidebar.basic" : "Basic",
   "sidebar.upgrade": "Upgrade",
   "sidebar.fourzerofour": "404",
   "sidebar.fivezerozero": "500",
   "sidebar.timeline" : "Timeline",
   "sidebar.stepper" : "Stepper",


   "widgets.newVisitors" : "New Visitors",
   "widgets.newSales": "New Sales",
   "widgets.bounceRate": "Bounce Rate",
   "widgets.earning": "Earning",
   "widgets.QuickLinks" : "Quick Links",
   "widgets.frequentlyUsedSectiopns": "Frequently Used Sections",
   "widgets.newUser" : "New Users",
   "widgets.welcome": "Hi John, Welcome back",
   "widgets.quickStats": "Today's Quick Stats",
   "widgets.views": "Views",
   "widgets.growth": "Growth",
   "widgets.rating": "Rating",
   "widgets.userGrowth" : "User % Growth",
   "widgets.avgUsers" : "Avg. Users",
   "widgets.avgTime" : "Avg. Time",
   "widgets.customTable": "Custom Table",
   "widgets.users": "Users",
   "widgets.revenue" : "Revenue",
   "widgets.conversionRate" : "Conversion Rate",
   "widgets.sessions": "Sessions",
   "widgets.totalRevenue": "Total Revenue",
   "widgets.totalOrders": "Total Orders",
   "widgets.totalUsers": "Total Users",
   "widgets.overallTrafficStatus": "Overall Traffic Status",
   "widgets.liveChat": "Live Chart",
   "widgets.tasksList" : "Tasks List",
   "widgets.newUsers": "New Users",
   "widgets.dynamicBarChart" : "Dynamic Bar Chart",
   "widgets.visitorsStats": "Visitor Stats",
   "widgets.recipients": "Recipients",
   "widgets.openRate": "Open Rate",
   "widgets.marketing": "Marketing",
   "widgets.sales": "Sales",
   "widgets.topProducts" : "Top Products",
   "widgets.trends": "Trends",
   "widgets.analyticsWebSessionsByRegion": "Analytics Web Sessions By Region",
   "widgets.keywordSearch": "Keyword Search",
   "widgets.visitorsDevicesUsed": "Devices Used By Visitors",
   "widgets.howmanyuserscamefromABC?" : "How Many Users Came From ABC?",
   "widgets.totalViews": "Total Views",
   "widgets.totalSales": "Total Sales",
   "widgets.viewMore": "View More",
   "widgets.newAccounts": "New Accounts",

   "component.products" : "Products",
   "component.signup" : "Sign Up",
   "component.loadmore": "Load More",
   "component.categories" : "Categories",
   "component.rating": "Rating",
   "component.priceFilter" : "Price Filter",
   "component.productDetails" : "Product Details",
   "component.backToProducts": "Back To Products",
   "component.addToCart": "Add To Cart",
   "component.viewCart" : "View Cart",
   "component.addtowishlist": "Add To Wishlist",
   "component.yourProductCart": "Your Product Cart",
   "component.continueShopping": "Continue Shopping",
   "component.myShoppingBag": "My Shopping Bag",
   "component.yourBill": "Your Bill",
   "component.saveShoppingBag" : "Save Shopping Bag",
   "component.proceedToCheckout": "Proceed To Checkout",   
   "component.subtotal": "Subtotal",
   "component.tax": "Tax",
   "component.total": "Total",
   "component.shippingAddress" : "Shipping Address",
   "component.payment": "Payment",
   "component.continueToPayment" : "Continue To Payment",
   "component.invoice" : "Invoice",
   "component.getMembership" : "Get Membership",
   "component.alreadyHaveAccount?" : "Already Have Account?",
   "component.continueAsGuest?": "Continue As Guest?",
	"components.compose": "Compose",
	"component.firebase" : "Firebase",
	"component.auth0" : "Auth 0",
	"component.youtubevideoplayer":"Youtube Video Player",
	"component.vimeovideoplayer":"Vimeo Video Player",

   "module.inbox": "Inbox",
   "module.drafts": "Drafts",
   "module.sent": "Sent",
   "module.trash": "Trash",
   "module.spam": "Spam",
   "module.primary" : "Primary",
   "module.social": "Social",
   "module.marketing": "Marketing",

   "component.contactGrid" :"Contact Grid",
   "component.addContact": "Add Contact",
   "component.allContacts": "All Contacts",
   "component.recentlyAdded": "Recently Added",
   "component.favourite": "Favourite",
   "component.todaysTasks":"Today's Tasks",
   "component.recentFollowers" : "Recent Followers",
   "component.settings" : "Settings",
   "component.home": "Home",
   "component.general" : "General",
   "component.account": "Account",
   "component.email": "Email",
   "component.faq": "Frequently Asked Questions",
   "component.generalEnquiries" : "General Enquiries",
   "component.accounts": "Accounts",
   "component.features" : "Features",
   "component.rulesAndPolicies" : "Rules And Policies",
   "component.chooseYourPlan" : "Choose Your Plan",
   "component.starter" : "STARTER",
   "component.professional": "PROFESSIONAL",
   "component.team": "TEAM",
   "component.enterprise": "ENTERPRISE",
   "component.monthly": "MONTHLY",
   "component.yearly": "YEARLY",
   "component.stillHaveQueries": "Still Have Queries ?",
   "component.callUsAnyTime" : "Call Us",
   "component.dropUsALine" : "Email Us",
   "component.backToPricingV1" : "Back To Pricing V1",
   "component.basic": "Basic",
   "component.standard": "Standard",
   "component.premium": "Premium",
   "component.purchasePlan": "Purchase Plan",
   "component.personal" : "Personal",
   "component.finish" : "Finish",
   "component.accountInformation" : "Account Information",
   "component.personalInformation" : "Personal Information",
   "component.editBlog": "Edit Blog",
   "component.deleteBlog" : "Delete Blog",
   "component.recentBlog" : "Recent Blog",
   "component.simpleAppBar": "Simple App Bar",
   "component.appBarWithAPrimarySearchField" : "App Bar With A Primary Search Field",
   "component.appBarWithMenu" : "App Bar With Menu",
   "component.imagesAvatar" : "Images Avatar",
   "component.letterAvatar" : "Letter Avatar",
   "component.sizes" : "Sizes",
   "component.iconAvatar" : "Icon Avatar",
   "component.grouped" : "Grouped",
   "component.withBadge" : "Avatars With Badge",
   "component.containedButtons" : "Contained Buttons",
   "component.textButtons" : "Text Buttons",
   "component.outlinedButtons" : "Outlined Buttons",
   "component.uploadButton" : "Upload Button",
   "component.buttonsWithIconsAndLabel": "Buttons With Icons And Label",
   "component.iconButtons": "Icon Buttons",
   "component.complexButtons": "Complex Buttons",
   "component.bottomNavigation": "Bottom Navigation",
   "component.bottomNavigationWithNoLabel": "Bottom Navigation With No Label",
   "component.outlinedChip": "Outlined Chip",
   "component.chipArray": "Chip Array",
   "component.simpleList" : "Simple List",
   "component.nesteList": "Nested List",
   "component.alignListItems": "Align List Items",
   "component.listWithCheckBox": "List With CheckBox",
   "component.listWithSwitch": "List With Switch",
   "component.simpleModal": "Simple Modal",
   "component.transition": "Transition",
   "component.aggridTable":"Ag-Grid Table",
   "component.megaMenu": "Mega Menu",
   "horizontalMenu.general": "General",
   "horizontalMenu.modules" : "Modules",
   "horizontalMenu.components": "Components",
   "horizontalMenu.applications" : "Applications",
   "component.upgradeYourPlanNow": "Upgrade Your Plan Now",
   "component.addYourDebitCreditCard" : "Add Your Debit or Credit Card",
   "component.termsandconditions" : "Terms And Conditions",
   "components.paymentGateways" : "Payment Gateways",
   "components.connectPaymentGateways" : "Connect Payment Gateways",
	"components.tutorials": "Checklist",
	"components.checklist": "Checklist Tutorial",
   "components.welcomeToHulk" : "Welcome To Hulk",
   "components.advancedTable" : "Advanced Table",
   "components.chatPanel" : "Chat Panel",
   "components.signaturePad": "Signature Pad",
   "components.help" : "Help",
   "components.clicktoknowmore" : "Click to know more",
   "components.clickhereifyouneedanyhelp" : "Click here If you need any help"
}